import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  where,
  limit,
  query,
  updateDoc
} from 'firebase/firestore'
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth'

export async function insertUser(email, type, name, uuid) {
  return addDoc(collection(getFirestore(), 'user'), {
    email,
    type,
    name,
    uuid
  })
}

export async function createUser(email, pw) {
  return createUserWithEmailAndPassword(getAuth(), email, pw)
}

export async function updateUser(email, type, name, uuid) {
  const q = query(
    collection(getFirestore(), 'user'),
    where('uuid', '==', uuid),
    limit(1)
  )
  const { docs } = await getDocs(q)
  const docRef = docs[0].ref
  return updateDoc(docRef, {
    email,
    type,
    name,
    uuid
  })
}

export async function getCurrentUser() {
  return getAuth().currentUser
}

export async function updateCurrentUser(user) {
  return getAuth().updateCurrentUser(user)
}

/**
 * 유형을 ENUM 형태로 바꿈
 * HR = 1
 * 영업 = 2
 * PM = 3
 * PMO = 4
 * 대표 = 5
 * 시스템 관리자 = 6
 */
export function toEnumFromType(type) {
  switch (type) {
    case 'HR':
      return 1
    case '영업':
      return 2
    case 'PM':
      return 3
    case 'PMO':
      return 4
    case '대표이사':
      return 5
    case '시스템 관리자':
      return 6
    default:
      return 0
  }
}

/**
 * ENUM을 유형 형태로 바꿈
 */
export function toTypeFromEnum(_enum) {
  _enum = typeof _enum === 'string' ? parseInt(_enum) : _enum

  switch (_enum) {
    case 1:
      return 'HR'
    case 2:
      return '영업'
    case 3:
      return 'PM'
    case 4:
      return 'PMO'
    case 5:
      return '대표이사'
    case 6:
      return '시스템 관리자'
    default:
      return '일반'
  }
}

export async function loginUser(email, pw) {
  return signInWithEmailAndPassword(getAuth(), email, pw)
}

export async function getUserFromDB(uid) {
  const q = query(
    collection(getFirestore(), 'user'),
    where('uuid', '==', uid),
    limit(1)
  )
  const { docs } = await getDocs(q)
  return docs[0].data()
}

export async function logout() {
  return signOut(getAuth())
}
