import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import 'react-datetime/css/react-datetime.css'
import Layout from './layouts/Layout'
import { UserProvider } from 'context/user'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    toast.configure({ autoClose: 3000, hideProgressBar: true })
  }, [])

  return (
    <UserProvider value={{ user, setUser, setLoggedIn, isLoggedIn: loggedIn }}>
      <Router basename={process.env.PUBLIC_URL}>
        <Layout />
      </Router>
      <ToastContainer />
    </UserProvider>
  )
}

export default App
