import React from 'react'
import PropTypes from 'prop-types'
import Flex from 'components/common/Flex'
import { Link } from 'react-router-dom'
import FalconCardHeader from 'components/common/FalconCardHeader'
import { Card } from 'react-bootstrap'
import Calendar from 'components/common/Calendar'
import FalconCardFooterLink from 'components/common/FalconCardFooterLink'

const Event = ({ details, isLast }) => {
  const {
    calendar,
    title,
    organizer,
    time,
    place,
    location,
    duration,
    interested
  } = details
  return (
    <Flex>
      <Calendar {...calendar} />
      <div className="flex-1 position-relative ps-3">
        <h6 className="fs-0 mb-0">
          <Link to="#!">{title}</Link>
        </h6>
        <p className="mb-1">Organized by {organizer}</p>
        <p className="text-1000 mb-0">Time: {time}</p>

        {duration && <p className="text-1000 mb-0">Duration: {duration}</p>}
        {interested && <p className="text-1000 mb-0">{interested}</p>}
        {location && <p className="text-1000 mb-0">Location: {location}</p>}

        <p className="mb-0">Place: {place}</p>
        {!isLast && <div className="border-dashed-bottom my-3"></div>}
      </div>
    </Flex>
  )
}

Event.propTypes = {
  details: PropTypes.shape({
    calendar: PropTypes.shape(Calendar.propTypes),
    title: PropTypes.string.isRequired,
    organizer: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    place: PropTypes.string.isRequired,
    location: PropTypes.string,
    duration: PropTypes.string,
    interested: PropTypes.string
  }),
  isLast: PropTypes.bool
}

const Events = ({ cardTitle, events }) => {
  return (
    <Card className="mb-3">
      <FalconCardHeader title={cardTitle} light />
      <Card.Body className="fs--1 border-bottom">
        {events.map((event, index) => (
          <Event
            key={event.id}
            details={event}
            isLast={index === events.length - 1}
          />
        ))}
      </Card.Body>
      <FalconCardFooterLink
        title="All Events"
        to="/events/event-list"
        size="sm"
      />
    </Card>
  )
}

Events.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(PropTypes.shape(Event.propTypes))
}

export default Events
