import React from 'react'
import Flex from 'components/common/Flex'
import LoginForm from 'components/authentication/LoginForm'
import AuthSimpleLayout from 'layouts/AuthSimpleLayout'

const Login = () => (
  <AuthSimpleLayout>
    <Flex justifyContent="between" alignItems="center" className="mb-2">
      <h5>Log in</h5>
    </Flex>
    <LoginForm />
  </AuthSimpleLayout>
)

export default Login
