import React from 'react'
import PropTypes from 'prop-types'
import { Col, Form, Row } from 'react-bootstrap'
import Datetime from 'react-datetime'

const InputField = ({ label, type = 'text', name, handleChange, ...rest }) => (
  <Form.Group as={Row} className="mb-3" controlId={name}>
    <Form.Label column xs={3} className="text-lg-end">
      {label}
    </Form.Label>
    <Col xs={9} sm={7}>
      {type === 'date' ? (
        <Datetime
          dateFormat="DD/MM/YYYY"
          timeFormat={false}
          inputProps={{
            placeholder: 'DD/MM/YYYY',
            className: 'form-control form-control-sm',
            name
          }}
          {...rest}
        />
      ) : (
        <Form.Control
          type={type}
          placeholder={label}
          size="sm"
          name={name}
          onChange={handleChange}
          {...rest}
        />
      )}
    </Col>
  </Form.Group>
)

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func
}

export default InputField
