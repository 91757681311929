// import { useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

export default function NormalInfo(props) {
  const {
    register,
    formState: { errors }
  } = useForm()

  // const [formData, setData] = useState({})

  return (
    <Card>
      <Card.Header>
        <Card.Title>직원 추가 &gt; 일반 정보</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form noValidate>
          <Form.Group>
            <Form.Label>이름</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="이름을 입력하세요."
              isInvalid={!!errors.name}
              {...register('name', {
                required: '이름은 필수입니다.'
              })}
            ></Form.Control>
            <Form.Label>직급</Form.Label>
            <Form.Control
              type="text"
              name="position"
              placeholder="직급을 입력하세요."
              isInvalid={!!errors.position}
              {...register('name', {
                required: '직급은 필수입니다.'
              })}
            ></Form.Control>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  )
}
