export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: '대시보드',
      active: true,
      icon: 'chart-pie',
      children: [
        {
          name: '기본',
          to: '/',
          active: true
        },
        {
          name: '분석',
          to: '/dashboard/analytics',
          active: true
        }
      ]
    }
  ]
}

export const systemRoutes = {
  label: '시스템 관리',
  children: [
    {
      name: '사용자 관리',
      icon: 'users',
      to: '/system/users',
      active: true
    }
  ]
}

export const hrRoutes = {
  label: '인사 관리',
  children: [
    {
      name: '조직 관리',
      icon: 'sitemap',
      to: '/hr/org',
      active: true
    },
    {
      name: '직원 관리',
      icon: 'grin',
      to: '/hr/employee',
      active: true
    }
  ]
}

export default [dashboardRoutes, systemRoutes, hrRoutes]
