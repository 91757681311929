import React from 'react'
import PageHeader from 'components/common/PageHeader'
import { Card, Row } from 'react-bootstrap'
import UserTable from 'components/system/UserTable'

const Users = () => {
  return (
    <Row className="g-3">
      <PageHeader
        title="시스템 관리 > 사용자 관리"
        description="PM/PL, PMO, 영업 관리부서, HR 관리부서, 대표이사 지정"
      />

      <Card>
        <Card.Header>
          <h5>사용자</h5>
        </Card.Header>
        <Card.Body className="pt-0">
          <UserTable />
        </Card.Body>
      </Card>
    </Row>
  )
}

export default Users
