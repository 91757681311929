import PageHeader from 'components/common/PageHeader'
import React from 'react'
import List from 'components/hr/employee/List'

const Employee = () => {
  return (
    <>
      <PageHeader
        title="인사 관리 > 직원 관리"
        description="직원에 대한 정보 및 관리"
        className="mb-3"
      />

      <List />
    </>
  )
}

export default Employee
