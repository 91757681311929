import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CardDropdown = ({ children }) => {
  return (
    <Dropdown className="font-sans-serif btn-reveal-trigger" align="end">
      <Dropdown.Toggle variant="link" size="sm" className="btn-reveal text-600">
        <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="border py-0">
        {children}
        {!children && (
          <div className="py-2">
            <Dropdown.Item>View</Dropdown.Item>
            <Dropdown.Item>Export</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className="text-danger">Remove</Dropdown.Item>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

CardDropdown.propTypes = {
  children: PropTypes.node
}

export default CardDropdown
