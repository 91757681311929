/**
 * 배열 요소를 n개씩 묶음
 * @param {any[]} arr
 * @param {number} n
 */
export default function division(arr, n) {
  const len = arr.length
  const cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0)
  const result = []

  for (let i = 0; i < cnt; i++) {
    result.push(arr.splice(0, n))
  }

  return result
}
