import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from 'components/common/Flex'
import { useOrgMap } from 'hooks/firestore/org'
import React, { useState, useEffect, createContext } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { getAllEmployee } from 'services/employee'
import { UserList } from '../common/UserCard'
import IconButton from 'components/common/IconButton'
import { Link } from 'react-router-dom'

export const ListContext = createContext({})

const EmployeeSearch = () => {
  const [searchValue, setSearchValue] = useState('')

  return (
    <div className="search-box" style={{ width: '15em' }}>
      <Form className="position-relative">
        <Form.Control
          type="search"
          placeholder="직원 검색"
          aria-label="search"
          onChange={({ target }) => setSearchValue(target.value)}
          value={searchValue}
          className="rounded-pill search-input"
        />
        <FontAwesomeIcon
          icon="search"
          className="position-absolute text-400 search-box-icon"
        />
      </Form>
    </div>
  )
}

const Menu = () => {
  return (
    <Flex>
      <Link to="/hr/employee/new">
        <IconButton
          variant="falcon-primary"
          icon="plus"
          className="rounded-pill me-3"
          title="직원 추가"
        ></IconButton>
      </Link>
      <EmployeeSearch />
    </Flex>
  )
}

const List = () => {
  const [allEmp, setAllEmp] = useState([])
  const orgNameMap = useOrgMap(allEmp)

  useEffect(() => {
    const run = async () => {
      const _allEmp = await getAllEmployee()
      setAllEmp(_allEmp)
    }

    run()

    return () => {
      setAllEmp([])
    }
  }, [])

  return (
    <ListContext.Provider value={{ orgNameMap }}>
      <Row className="g-3 mb-3">
        <Col xs={12}>
          <Card>
            <Card.Header
              as={Flex}
              style={{ justifyContent: 'space-between' }}
              className="pb-0"
            >
              <Card.Title>직원 리스트</Card.Title>
              <Menu />
            </Card.Header>
            <Card.Body>
              {allEmp.length ? (
                <UserList users={allEmp} routable />
              ) : (
                <span>직원 정보가 없습니다.</span>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </ListContext.Provider>
  )
}

export default List
