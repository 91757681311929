import {
  collection,
  getFirestore,
  getDocs,
  addDoc,
  deleteDoc,
  query,
  where,
  limit,
  updateDoc
} from '@firebase/firestore'
import { v4 as uuid } from 'uuid'

export async function getAllOrgDocs() {
  return getDocs(collection(getFirestore(), 'org'))
}

export async function getOrgTree() {
  const allData = []
  const tree = []
  const { docs } = await getAllOrgDocs()

  docs.forEach((doc) => allData.push(doc.data()))

  const rootIdx = allData.findIndex(
    (data) => data.root && !data.isUser && data.parentUuid === null
  )
  const root = allData[rootIdx]
  root.children = []

  tree.push(root)
  allData.splice(rootIdx, 1)

  const map = {}

  allData.forEach((data) => {
    if (map[data.parentUuid]) {
      map[data.parentUuid].push(data)
      return
    }
    map[data.parentUuid] = [data]
  })

  const stack = [tree[0]]

  while (stack.length) {
    const t = stack.pop()

    if (map[t.uuid]) {
      t.children = map[t.uuid]
      stack.push(...t.children)
    }
  }

  return tree
}

/**
 * @param {string} name
 * @param {string} description
 * @param {string} parentUuid
 */
export async function addOrg(name, description, parentUuid) {
  const orgUuid = uuid()
  const key = orgUuid
  const title = name

  return addDoc(collection(getFirestore(), 'org'), {
    name,
    description,
    title,
    key,
    uuid: orgUuid,
    parentUuid
  })
}

export async function deleteOrg(uuid) {
  // TODO: 조직 삭제시 조직에 속해있는 직원 처리
  // TODO: 상위 조직이 삭제된 경우 하위 조직까지 삭제 처리
  if (!uuid) throw new Error('uuid required')

  const orgRef = collection(getFirestore(), 'org')
  const q = query(orgRef, where('uuid', '==', uuid), limit(1))
  const qs = await getDocs(q)

  await deleteDoc(qs.docs[0].ref)

  return true
}

export async function getOrgNameMap(uuids) {
  if (!uuids.length) return {}

  const resultMap = {}
  const orgRef = collection(getFirestore(), 'org')
  const q = query(orgRef, where('uuid', 'in', uuids))
  const docs = await getDocs(q)

  docs.forEach((doc) => {
    const data = doc.data()

    if (!resultMap[data.uuid]) {
      resultMap[data.uuid] = data.name || data.title
    }
  })

  return resultMap
}

// 유저 조직 이관
export async function moveUserOrg(
  userUuid,
  targetOrgUuid,
  targetOrgParentUuid,
  toRoot = false
) {
  if (!userUuid || !targetOrgUuid) throw new Error('필수값 누락')

  console.log('userUuid', userUuid)
  console.log('targetOrgUuid', targetOrgUuid)
  console.log('targetOrgParentUuid', targetOrgParentUuid)
  console.log('toRoot', toRoot)

  const empColRef = collection(getFirestore(), 'employee')
  const empQuery = query(empColRef, where('uuid', '==', userUuid), limit(1))
  const empDoc = await getDocs(empQuery)
  const empDocRef = empDoc.docs[0].ref

  await updateDoc(empDocRef, {
    orgUuid: targetOrgUuid
  })

  // const orgColRef = collection(getFirestore(), 'org')
  // const orgQuery = query(
  //   orgColRef,
  //   where('isUser', '==', true),
  //   where('userUuid', '==', userUuid),
  //   limit(1)
  // )
  // const orgDoc = await getDocs(orgQuery)
  // const orgDocRef = orgDoc.docs[0].ref

  // await updateDoc(orgDocRef, {
  //   uuid: toRoot ? 'root' : targetOrgUuid,
  //   parentUuid: toRoot ? null : targetOrgParentUuid
  // })
}
