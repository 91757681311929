import React, { createContext, useContext, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import Flex from 'components/common/Flex'
import styles from 'styles/hr.module.css'
import division from 'utils/division'
import { ListContext } from '../employee/List'
import { Col, Row } from 'react-bootstrap'
import Wrapper from 'utils/Wrapper'
import { Link } from 'react-router-dom'

const UserCardContext = createContext({})

export const UserList = ({
  users,
  userColProps,
  routable,
  selectable,
  selectEvent
}) => {
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (users.length) {
      setRows(division([...users], 4))
    }

    return () => {
      setRows([])
    }
  }, [users])

  useEffect(() => {}, [])

  return (
    <UserCardContext.Provider
      value={{ users, userColProps, routable, rows, selectable, selectEvent }}
    >
      {rows.map((row, index) => (
        <UserRow key={index} userRow={row} />
      ))}
    </UserCardContext.Provider>
  )
}

UserList.defaultProps = {
  selectable: false,
  selectEvent: null,
  routable: true
}

export const UserRow = ({ userRow }) => {
  return (
    <Row className="g-4 mb-2">
      {userRow.map((row, index) => (
        <UserCol key={index} user={row} />
      ))}
    </Row>
  )
}

export const UserCol = ({ user }) => {
  const { orgNameMap } = useContext(ListContext)
  const { userColProps } = useContext(UserCardContext)

  return (
    <Col {...userColProps}>
      <UserCard user={user} orgName={orgNameMap[user.orgUuid]} />
    </Col>
  )
}

UserList.defaultProps = {
  userColProps: {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 3
  }
}

const UserCard = ({ user, orgName }) => {
  const { selectable, selectEvent, routable } = useContext(UserCardContext)

  return (
    <Wrapper
      condition={routable}
      wrapper={(children) => (
        <Link className={styles.userCardLink} to={`/hr/employee/${user.uuid}`}>
          {children}
        </Link>
      )}
    >
      <Flex
        className={
          (styles.userCard,
          'text-black',
          selectable && styles.selectableUserCard)
        }
        alignItems="center"
        direction="column"
        onClick={selectable ? () => selectEvent(user) : null}
      >
        <div className="mb-2">
          <img
            src="https://via.placeholder.com/200x150"
            alt="2"
            className="rounded-3"
          />
        </div>
        <div className="fs-1 fw-bold">
          {user.name} {user.level}
        </div>
        {orgName && <div>{orgName}</div>}
      </Flex>
    </Wrapper>
  )
}

UserCard.propTypes = {
  user: propTypes.object,
  orgName: propTypes.string,
  routable: propTypes.bool
}

UserCard.defaultProps = {
  user: {},
  orgName: '',
  routable: true
}

export default UserCard
