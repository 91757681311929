import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap'
import { getUserFromDB, loginUser } from 'services/user'
import UserContext from 'context/user'
import { useHistory, useLocation } from 'react-router-dom'

const LoginForm = ({ hasLabel, layout }) => {
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  })

  const [loading, setLoading] = useState(false)
  const [checkLogin, setCheckLogin] = useState(true)
  const { isLoggedIn, setUser, setLoggedIn } = useContext(UserContext)
  const history = useHistory()
  const location = useLocation()

  // Handler
  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()

    try {
      const { user } = await loginUser(formData.email, formData.password)
      const dbUser = await getUserFromDB(user.uid)
      setUser(dbUser)
      setLoggedIn(true)
    } catch (error) {
      switch (error.code) {
        case 'auth/user-not-found':
          toast.error('이메일을 확인해주세요.')
          break
        case 'auth/wrong-password':
          toast.error('비밀번호를 확인해주세요.')
          break
        default:
          console.error(error)
          toast.error('알 수 없는 오류')
      }
    } finally {
      setLoading(false)
    }
  }

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    if (isLoggedIn) {
      if (location.search) {
        const params = new URLSearchParams(location.search)
        history.push({ pathname: params.get('url') })
      } else {
        history.push('/')
      }
    }
  }, [isLoggedIn])

  useEffect(() => {
    setTimeout(() => {
      setCheckLogin(false)
    }, 1000)

    return () => {
      setCheckLogin(true)
      setFormData({
        email: '',
        password: '',
        remember: false
      })
    }
  }, [])

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="ms-2 mb-0">
              Remember Me
            </Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100 py-2"
          disabled={
            !formData.email || !formData.password || loading || checkLogin
          }
        >
          {/* {checkLogin ? '로그인 정보 확인중...' : '로그인'} */}
          {checkLogin ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                className="me-2"
              />
              <span className="align-top">로그인 정보 확인중...</span>
            </>
          ) : (
            '로그인'
          )}
        </Button>
      </Form.Group>
    </Form>
  )
}

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
}

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
}

export default LoginForm
