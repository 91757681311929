import React, { useEffect, useRef, useState } from 'react'
import PropType from 'prop-types'
import { Col, Form, Modal, Row, Button } from 'react-bootstrap'
import FalconCloseButton from 'components/common/FalconCloseButton'
import {
  createUser,
  getCurrentUser,
  updateCurrentUser,
  insertUser,
  toEnumFromType,
  updateUser as fUpdateUser
} from 'services/user'

const UserEditModal = ({ active, onHide, updateUser }) => {
  const formRef = useRef(null)
  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertText, setAlertText] = useState('')

  // form
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [type, setType] = useState('')

  const onSubmit = async (event) => {
    event.preventDefault()
    event.stopPropagation()

    setLoading(true)
    setValidated(true)

    if (
      !email ||
      !name ||
      !type ||
      !/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(email)
    ) {
      setLoading(false)
      return
    }

    if (!updateUser) {
      try {
        /**
         * 파이어베이스 이슈
         * 이메일/비밀번호 기반으로 유저를 만들 경우 생성된 다음 바로 그 계정으로 로그인됨
         * 따라서 현재 계정을 생성하고 있는 계정 정보를 변수에 저장한 뒤 계정을 생성하고 저장해놨던
         * 유저 데이터로 덮어 씌움
         */
        const currentUser = await getCurrentUser()
        const userCredential = await createUser(email, 'growth2014!') // 테스트임
        await insertUser(
          email,
          toEnumFromType(type),
          name,
          userCredential.user.uid
        )
        await updateCurrentUser(currentUser)
        onHide(true)
      } catch (error) {
        switch (error.code) {
          case 'auth/email-already-in-use':
            setShowAlert(true)
            setAlertText('이미 존재하는 이메일입니다.')
            break
          default:
            setShowAlert(true)
            setAlertText(error.code)
        }
      } finally {
        setLoading(false)
      }
    } else {
      try {
        await fUpdateUser(email, toEnumFromType(type), name, updateUser.uuid)
        onHide(true)
      } catch (error) {
        switch (error.code) {
          default:
            setShowAlert(true)
            setAlertText(error.code)
        }
      }
    }
  }

  useEffect(() => {
    if (!active) {
      setValidated(false)
      setLoading(false)
      setAlertText('')
      setShowAlert(false)
    } else {
      if (updateUser) {
        setEmail(updateUser.email)
        setName(updateUser.name)
        setType(updateUser.typeName)
      }
    }
  }, [active, updateUser])

  return (
    <>
      <Modal className="mt-5" show={active} onHide={onHide} size="lg">
        <Modal.Header>
          <Modal.Title>유저 관리</Modal.Title>
          <FalconCloseButton onClick={onHide} />
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            onSubmit={onSubmit}
            noValidate
            validated={validated}
          >
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formEmail">
                <Form.Label>이메일</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="이메일 입력"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="formName">
                <Form.Label>이름</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="이름 입력"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formType">
                <Form.Label>유형</Form.Label>
                <Form.Select
                  aria-label="Default"
                  required
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option>영업</option>
                  <option>HR</option>
                  <option>PM</option>
                  <option>PMO</option>
                  <option>대표이사</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Modal.Footer>
              {showAlert && (
                <strong className="text-danger">{alertText}</strong>
              )}
              <Button type="submit" variant="falcon-primary" disabled={loading}>
                {loading ? '제출중...' : '제출'}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

UserEditModal.propTypes = {
  chilren: PropType.node,
  active: PropType.bool,
  type: PropType.number,
  updateUser: PropType.object
}

export default UserEditModal
