import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Card, ButtonGroup, Button, Spinner } from 'react-bootstrap'
import Tree from 'rc-tree'
import 'rc-tree/assets/index.css'
import 'styles/tree-extend.css'
import styles from 'styles/avatar.module.css'
import { getOrgTree, deleteOrg } from 'services/org'
import { selectEmployeeFromUuids } from 'services/employee'
import Flex from 'components/common/Flex'
import OrgAddModal from './OrgAddModal'
import Avatar from 'components/common/Avatar'
import Signal from 'assets/img/icons/signal.png'
import User from 'assets/img/team/avatar.png'
import { toast } from 'react-toastify'
import OrgAddEmpModal from './OrgAddEmpModal'
import { UserList } from '../common/UserCard'
import { useOrgMap } from 'hooks/firestore/org'
import { ListContext } from '../employee/List'

const TreeIconComp = ({ data }) => {
  return (
    <Avatar
      className={styles.avatarInline}
      src={data.isUser ? User : Signal}
      size="s"
    />
  )
}

const OrgTree = () => {
  const [selectedOrg, setSelectedOrg] = useState(null)
  const [treeData, setTreeData] = useState([])
  const [childrenUuids, setChildrenUuids] = useState([])
  const [employee, setEmployee] = useState([])
  const treeRef = useRef(null)

  // Modal
  const [addModalActive, setAddModalActive] = useState(false)
  const [empModalActive, setEmpModalActive] = useState(false)

  // Loading
  const [buttonGroupLoading, setButtonGroupLoading] = useState(false)
  const [treeLoading, setTreeLoading] = useState(true)
  const [employeeLoading, setEmployeeLoading] = useState(false)

  // Custom
  const orgNameMap = useOrgMap(employee)

  const getTreeData = async () => {
    const tree = await getOrgTree()
    setSelectedOrg(null)
    setTreeData(tree)
    setTreeLoading(false)
  }

  const getNestedUuids = (tree, gather) => {
    if (tree.children && tree.children.length) {
      gather.push(tree.uuid)
      tree.children.forEach((node) => getNestedUuids(node, gather))
    } else if (tree.isUser) {
      return
    } else {
      gather.push(tree.uuid)
    }
  }

  useEffect(() => {
    if (!addModalActive || !empModalActive) {
      getSelectEmployee()
    }
  }, [addModalActive, empModalActive])

  useEffect(() => {
    getTreeData()

    return () => {
      setSelectedOrg(null)
      setTreeData([])
    }
  }, [])

  useEffect(() => {
    if (selectedOrg && !selectedOrg.isUser) {
      const result = []
      getNestedUuids(selectedOrg, result)
      setChildrenUuids([...new Set(result)])
    } else {
      setChildrenUuids([])
    }
  }, [selectedOrg])

  useEffect(() => {
    if (childrenUuids && childrenUuids.length) {
      getSelectEmployee(0)
    }
  }, [childrenUuids])

  const getSelectEmployee = () => {
    setEmployeeLoading(true)
    selectEmployeeFromUuids(childrenUuids)
      .then((result) => setEmployee(result))
      .catch((error) => toast.error(error))
      .finally(() => setEmployeeLoading(false))
  }

  const handleSelect = (keys, info) => {
    if (info.selectedNodes.length) {
      if (info?.selectedNodes[0]?.isUser) setSelectedOrg(null)
      else setSelectedOrg(info.selectedNodes[0])
    } else setSelectedOrg(null)
  }

  const handleDeleteOrg = async () => {
    if (!selectedOrg) {
      toast.error('조직을 선택해주세요.')
      return
    } else if (selectedOrg.root) {
      toast.error('최상위 조직은 삭제할 수 없습니다.')
      return
    } else if (selectedOrg.isUser) {
      toast.error('조직이 아닙니다.')
      return
    }

    setButtonGroupLoading(true)

    try {
      const isDeleted = await deleteOrg(selectedOrg.uuid)
      await getTreeData()
      setSelectedOrg(null)

      if (isDeleted) {
        toast.success('조직이 삭제되었습니다.', { hideProgressBar: false })
      }
    } catch (error) {
      toast.error(error.message, { hideProgressBar: false })
    } finally {
      setButtonGroupLoading(false)
    }
  }

  return (
    <>
      <Row className="g-3 mb-3">
        <Col xs={12} md={6}>
          <Card className="h-100">
            <Card.Header>
              <h5>조직 구성도</h5>
            </Card.Header>
            <Card.Body className="pt-0">
              <Flex justifyContent="end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    disabled={!selectedOrg || buttonGroupLoading}
                    onClick={() => {
                      setAddModalActive(true)
                    }}
                  >
                    추가
                  </Button>
                  <Button
                    variant="primary"
                    disabled={!selectedOrg || buttonGroupLoading}
                    onClick={() => handleDeleteOrg()}
                  >
                    삭제
                  </Button>
                  <Button
                    variant="primary"
                    disabled={!selectedOrg || buttonGroupLoading}
                  >
                    편집
                  </Button>
                  <Button
                    variant="primary"
                    disabled={!selectedOrg || buttonGroupLoading}
                    onClick={() => setEmpModalActive(true)}
                  >
                    인원 편성
                  </Button>
                </ButtonGroup>
              </Flex>
              {treeLoading ? (
                <Spinner />
              ) : treeData.length ? (
                <Tree
                  className="my-3"
                  icon={TreeIconComp}
                  ref={treeRef}
                  treeData={treeData}
                  defaultExpandAll
                  defaultSelectedKeys={['jj']}
                  onSelect={(keys, info) => handleSelect(keys, info)}
                />
              ) : (
                <div>데이터가 존재하지 않음</div>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card className="h-100">
            <Card.Header>
              <h5>조직 구성원</h5>
            </Card.Header>
            <Card.Body className="pt-0">
              {employeeLoading ? (
                <Spinner />
              ) : selectedOrg ? (
                <ListContext.Provider value={{ orgNameMap }}>
                  <UserList users={employee} userColProps={{ xs: 12, md: 6 }} />
                </ListContext.Provider>
              ) : (
                <div className="text-500">선택된 조직이 없습니다.</div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <OrgAddModal
        active={addModalActive}
        setActive={setAddModalActive}
        org={selectedOrg}
        refresh={getTreeData}
      />
      <OrgAddEmpModal
        active={empModalActive}
        setActive={setEmpModalActive}
        org={selectedOrg}
        refresh={getTreeData}
        childrenUuids={childrenUuids}
      />
    </>
  )
}

export default OrgTree
