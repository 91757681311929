import { useState, useEffect } from 'react'
import { getFirestore, getDocs, collection } from 'firebase/firestore'

export function useFetchAllUsers() {
  const [loading, setLoading] = useState(true)
  const [allUsers, setAllUsers] = useState([])

  const refresh = () => {
    setAllUsers([])
    setLoading(true)

    getDocs(collection(getFirestore(), 'user'))
      .then((qr) => {
        qr.forEach((qs) => setAllUsers((state) => state.concat([qs.data()])))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    refresh()

    return () => {
      setLoading(true)
      setAllUsers([])
    }
  }, [])

  return { loading, allUsers, refresh }
}
