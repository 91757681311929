import { createContext } from 'react'

const UserContext = createContext({
  user: null,
  isLoggedIn: false,
  setUser: () => {},
  setLoggedIn: () => {}
})

const { Provider: UserProvider, Consumer: UserConsumer } = UserContext

export { UserProvider, UserConsumer }

export default UserContext
