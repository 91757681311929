import UserContext from 'context/user'
import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router'

const AuthenticationGuard = ({ render: Render, ...routeProps }) => {
  const { isLoggedIn } = useContext(UserContext)

  return (
    <Route
      {...routeProps}
      render={() => (isLoggedIn ? <Render /> : <Redirect to="/login" />)}
    />
  )
}

export default AuthenticationGuard
