import React, { useCallback, useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import is from 'is_js'
import MainLayout from './MainLayout'
import SettingsToggle from 'components/settings-panel/SettingsToggle'
import SettingsPanel from 'components/settings-panel/SettingsPanel'

import ErrorLayout from './ErrorLayout'
import Landing from 'components/pages/landing/Landing'
import Login from 'components/authentication/simple/Login'

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList
  const { pathname } = useLocation()

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows')
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome')
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox')
    }
  }, [HTMLClassList])

  const isShowToggle = useCallback(() => {
    const noVisible = ['/landing', '/errors', '/login']
    return !noVisible.filter((path) => pathname.startsWith(path)).length
  }, [pathname])

  return (
    <>
      <Switch>
        <Route path="/landing" component={Landing} />
        <Route path="/errors" component={ErrorLayout} />
        <Route path="/login" exact component={Login} />
        <Route component={MainLayout} />
        <Redirect to="/errors/404" />
      </Switch>
      {isShowToggle() && <SettingsToggle />}
      {/* <SettingsToggle /> */}
      <SettingsPanel />
    </>
  )
}

export default Layout
