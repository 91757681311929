import React, { useEffect, useRef, useState } from 'react'
import propTypes from 'prop-types'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import FalconCloseButton from 'components/common/FalconCloseButton'
import { toast } from 'react-toastify'
import { addOrg } from 'services/org'

const initFormData = {
  orgName: '',
  orgDescription: ''
}

const OrgAddModal = ({ active, setActive, org, refresh }) => {
  const formRef = useRef(null)
  const [validated, setValidated] = useState(false)

  const [formData, setFormData] = useState(initFormData)
  const [submitLoading, setSubmitLoading] = useState(false)

  const onSubmit = async () => {
    setValidated(true)
    setSubmitLoading(true)

    if (!formRef.current.checkValidity()) {
      toast.error('필수 값을 입력해주세요.', { hideProgressBar: true })
      setSubmitLoading(false)
      return
    }

    try {
      console.log(org)
      await addOrg(formData.orgName, formData.orgDescription, org.uuid)
      toast.success(formData.orgName + ' 조직이 추가되었습니다.', {
        hideProgressBar: true
      })
      setActive(false)
      refresh()
    } catch (error) {
      console.log(error)
      toast.error('조직 추가에 실패하였습니다.', { hideProgressBar: true })
    } finally {
      setSubmitLoading(false)
    }
  }

  useEffect(() => {
    if (!active) {
      setFormData(initFormData)
      setSubmitLoading(false)
      setValidated(false)
    }
  }, [active])

  return (
    <Modal show={active} onHide={() => setActive(false)} size="lg">
      <Modal.Header>
        <Modal.Title>조직 추가 - {org?.title}</Modal.Title>
        <FalconCloseButton onClick={() => setActive(false)} />
      </Modal.Header>
      <Modal.Body>
        <Form
          ref={formRef}
          onSubmit={onSubmit}
          noValidate
          validated={validated}
        >
          <Row className="mb-3 g-3">
            <Form.Group as={Col} xs={12} sm={4} controlId="orgName">
              <Form.Label>조직 이름</Form.Label>
              <Form.Control
                type="text"
                placeholder="조직 이름을 입력하세요."
                required
                value={formData.orgName}
                onChange={(e) =>
                  setFormData({ ...formData, orgName: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={8} controlId="orgName">
              <Form.Label>조직 설명</Form.Label>
              <Form.Control
                type="text"
                placeholder="조직 설명을 입력하세요."
                required
                value={formData.orgDescription}
                onChange={(e) =>
                  setFormData({ ...formData, orgDescription: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="falcon-default" onClick={() => setActive(false)}>
          취소
        </Button>
        <Button
          type="submit"
          variant="falcon-primary"
          onClick={() => onSubmit()}
          disabled={submitLoading}
        >
          {submitLoading ? '추가중...' : '추가'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

OrgAddModal.propTypes = {
  active: propTypes.bool,
  setActive: propTypes.func,
  org: propTypes.object,
  refresh: propTypes.func
}

export default OrgAddModal
