import React, { useContext } from 'react'
import { Dropdown } from 'react-bootstrap'
import AvatarImg from 'assets/img/team/avatar.png'
import Avatar from 'components/common/Avatar'
import styles from 'styles/avatar.module.css'
import { logout } from 'services/user'
import UserContext from 'context/user'

const ProfileDropdown = () => {
  const { setUser, setLoggedIn } = useContext(UserContext)

  const handleLogout = () => {
    logout().then(() => {
      setUser(null)
      setLoggedIn(false)
    })
  }

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        className={`pe-0 nav-link ${styles.profile}`}
      >
        <Avatar src={AvatarImg} size="xl" className={styles.avatar} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item onClick={() => handleLogout()}>Logout</Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ProfileDropdown
