import PageHeader from 'components/common/PageHeader'
import React, { useCallback, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router'
import { getEmployeeByUuid } from 'services/employee'

const EmployeeDetail = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [employee, setEmployee] = useState(null)

  const params = useParams()

  useEffect(() => {
    getEmployee()
  }, [])

  const getEmployee = async () => {
    const { uuid } = params
    const result = await getEmployeeByUuid(uuid)

    if (result) {
      setEmployee(result)
      setIsLoading(false)
    }
  }

  const displayName = useCallback(() => {
    if (!employee) return ''
    return `${employee.name} ${employee.level}`
  }, [employee])

  return isLoading && !employee ? (
    <Spinner />
  ) : (
    <>
      <PageHeader
        title={`인사 관리 > 직원 관리 > ${displayName()}`}
        description="직원의 일반 정보 및 이력서, 직책, 직무, 조직, 기술 열람 및 관리"
        className="pb-0"
      />
    </>
  )
}

export default EmployeeDetail
