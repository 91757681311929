import PageHeader from 'components/common/PageHeader'
import NormalInfo from 'components/hr/employee/NormalInfo'
import React from 'react'

const EmployeeNew = () => {
  return (
    <>
      <PageHeader
        title="직원 관리 > 직원 추가"
        description="직원 추가 및 정보 입력"
        className="mb-3"
      />

      {/* 일반 정보 */}
      <NormalInfo />
    </>
  )
}

export default EmployeeNew
