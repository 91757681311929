import PageHeader from 'components/common/PageHeader'
import OrgTree from 'components/hr/org/OrgTree'
import React from 'react'

const Org = () => {
  return (
    <>
      <PageHeader
        title="인사 관리 > 조직 관리"
        description="조직에 대한 관리, 조직원 추가 및 조직 설명 수정"
        className="mb-3"
      />

      <OrgTree />
    </>
  )
}

export default Org
