import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Main from './Main'
import 'helpers/initFA'
import { initializeApp } from 'firebase/app'

function firebaseSetup(): void {
  initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    appId: process.env.REACT_APP_APP_ID,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET
  })
}

firebaseSetup()

ReactDOM.render(
  <React.StrictMode>
    <Main>
      <App />
    </Main>
  </React.StrictMode>,
  document.getElementById('main')
)
