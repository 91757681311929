import { useEffect, useState } from 'react'
import { getOrgNameMap } from 'services/org'

export function useOrgMap(allEmp) {
  const [orgNameMap, setOrgNameMap] = useState({})

  useEffect(() => {
    if (allEmp && allEmp.length) {
      fetchOrgNameMap(allEmp)
    }

    return () => {
      setOrgNameMap({})
    }
  }, [allEmp])

  const fetchOrgNameMap = async (allEmp) => {
    const uuids = [...new Set(allEmp.map((emp) => emp.orgUuid))]
    const result = await getOrgNameMap(uuids)

    setOrgNameMap(result)
  }

  return orgNameMap
}
