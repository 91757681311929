import React, { useContext, useEffect } from 'react'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import Dashboard from 'components/dashboards/default'
import NavbarTop from 'components/navbar/top/NavbarTop'
import NavbarVertical from 'components/navbar/vertical/NavbarVertical'
import AppContext from 'context/Context'
import MainRoutes from './MainRoutes'
import UserContext from 'context/user'
import { getAuth, onAuthStateChanged } from '@firebase/auth'
import { getUserFromDB } from 'services/user'
import { toast } from 'react-toastify'

const MainLayout = () => {
  const location = useLocation()
  const history = useHistory()
  const { isLoggedIn, setUser, setLoggedIn } = useContext(UserContext)

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext)

  useEffect(() => {
    setTimeout(() => {
      if (location.hash) {
        const id = location.hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }
    }, 0)

    onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        try {
          const dbUser = await getUserFromDB(user.uid)
          setUser(dbUser)
          setLoggedIn(true)
        } catch (error) {
          toast.error('로그인 에러')
        }
      } else {
        setUser(null)
        setLoggedIn(false)
      }
    })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    if (!isLoggedIn) {
      history.push({ pathname: '/login', search: '?url=' + location.pathname })
    }
  }, [isLoggedIn])

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      <div className="content">
        <NavbarTop />
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <MainRoutes />
        </Switch>
      </div>
    </div>
  )
}

export default MainLayout
