import React from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import PageHeader from 'components/common/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FalconComponentCard from 'components/common/FalconComponentCard'
import Datetime from 'react-datetime'

const timeCode = `function TimePickerExample() {
  const [startTime, setStartTime] = useState(null);
  
  return (
    <Datetime
      dateFormat='YYYY-MM-DD'
      timeFormat='hh : mm'
      value={startTime}
      onChange={setStartTime}
      inputProps={{
        placeholder:'Select Time'
      }}
    />
  );
}`

const dateCode = `function DatePickerExample() {
  const [startDate, setStartDate] = useState(null);
  
  return (
    <Datetime
      timeFormat={false}
      value={startDate}
      onChange={setStartDate}
      inputProps={{
        placeholder:'Select Date'
      }}
    />
  );
}`

const dateTimeCode = `function DatePickerExample() {
  const [startDate, setStartDate] = useState(null);
  
  return (
    <Datetime
      value={startDate}
      onChange={setStartDate}
      inputProps={{
        placeholder:'Select Date & Time'
      }}
    />
  );
}`

const formatCode = `function TimePickerExample() {
  const [startTime, setStartTime] = useState(null);
  
  return (
    <Datetime
      dateFormat='MM/DD/YYYY'
      timeFormat='hh:mm a'
      value={startTime}
      onChange={setStartTime}
      inputProps={{
        placeholder:'MM/DD/YYYY'
      }}
    />
  );
}`

const DatePicker = () => (
  <>
    <PageHeader
      title="Date Picker"
      description="React-Falcon uses <strong>React Datetime</strong> as datepicker. <strong>React Datetime</strong> is highly customizable and it even allows to edit date's milliseconds."
      className="mb-3"
    >
      <Button
        href="https://github.com/arqex/react-datetime"
        target="_blank"
        variant="link"
        size="sm"
        className="ps-0"
      >
        React-Datetime Documentation
        <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
      </Button>
    </PageHeader>

    <Row className="mb-3 g-3">
      <Col lg={6}>
        <FalconComponentCard noGuttersBottom>
          <FalconComponentCard.Header title="Date picker" />
          <FalconComponentCard.Body
            code={dateCode}
            scope={{ Datetime }}
            language="jsx"
          />
        </FalconComponentCard>
      </Col>
      <Col lg={6}>
        <FalconComponentCard noGuttersBottom>
          <FalconComponentCard.Header title="Time picker" />

          <FalconComponentCard.Body
            code={timeCode}
            scope={{ Datetime }}
            language="jsx"
          />
        </FalconComponentCard>
      </Col>
    </Row>

    <Row className="mb-3 g-3">
      <Col lg={6}>
        <FalconComponentCard noGuttersBottom>
          <FalconComponentCard.Header title="Date picker" />
          <FalconComponentCard.Body
            code={dateTimeCode}
            scope={{ Datetime }}
            language="jsx"
          />
        </FalconComponentCard>
      </Col>
      <Col lg={6}>
        <FalconComponentCard noGuttersBottom>
          <FalconComponentCard.Header title="Date Format" />
          <FalconComponentCard.Body
            code={formatCode}
            scope={{ Datetime }}
            language="jsx"
          />
        </FalconComponentCard>
      </Col>
    </Row>
  </>
)

export default DatePicker
