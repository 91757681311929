import ActionButton from 'components/common/ActionButton'
import Flex from 'components/common/Flex'
import IconButton from 'components/common/IconButton'
import { useFetchAllUsers } from 'hooks/firestore/user'
import React, { useCallback, useEffect, useState } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { toTypeFromEnum } from 'services/user'
import UserEditModal from './UserEditModal'

const columns = ['이메일', '이름', '유형', '편집']
const fetchUserKey = ['email', 'name', 'typeName'] // 가져와야할 유저 키

const UserTable = () => {
  const [modalActive, setModalActive] = useState(false)
  const [updateUser, setUpdateUser] = useState(null)
  const { loading, allUsers, refresh } = useFetchAllUsers()

  const typedUsers = useCallback(() => {
    if (!loading) {
      return allUsers.map((user) => ({
        ...user,
        typeName: toTypeFromEnum(user.type)
      }))
    }
  }, [loading, allUsers])

  const hide = (isRefresh = false) => {
    if (isRefresh) refresh()

    setModalActive(false)
  }

  const handleRemove = (user) => {}

  const handleUpdate = (user) => {
    setUpdateUser(user)
    setModalActive(true)
  }

  useEffect(() => {
    if (!modalActive) {
      setUpdateUser(null)
    }
  }, [modalActive])

  return loading ? (
    <Spinner animation="border" role="status" />
  ) : (
    <>
      <Flex justifyContent="end" className="mb-4">
        <IconButton
          variant="falcon-default"
          icon="plus"
          iconAlign="right"
          className="rounded-pill"
          onClick={() => setModalActive(true)}
        ></IconButton>
      </Flex>
      <Table striped bordered hover>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {typedUsers().map((user) => (
            <tr key={user.uuid}>
              {fetchUserKey.map((key) => (
                <td key={key}>{user[key]}</td>
              ))}
              <td width={100}>
                <ActionButton
                  icon="trash-alt"
                  title="삭제"
                  variant="action"
                  className="p-0 me-2"
                  onClick={() => handleRemove(user)}
                />
                <ActionButton
                  icon="edit"
                  variant="action"
                  className="p-0"
                  title="수정"
                  onClick={() => handleUpdate(user)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <UserEditModal
        active={modalActive}
        onHide={hide}
        updateUser={updateUser}
      />
    </>
  )
}

export default UserTable
