import {
  getDocs,
  query,
  where,
  collection,
  getFirestore
} from '@firebase/firestore'

/**
 * @param {string[]} uuids
 */
export async function selectEmployeeFromUuids(uuids) {
  if (!uuids || !uuids.length) throw new Error('uuids required')

  const result = []

  const colRef = collection(getFirestore(), 'employee')
  const q = query(colRef, where('orgUuid', 'in', uuids))
  const docs = await getDocs(q)

  docs.forEach((qs) => result.push(qs.data()))

  return result
}

export async function getAllEmployee() {
  const result = []
  const docs = await getDocs(collection(getFirestore(), 'employee'))

  docs.forEach((qs) => result.push(qs.data()))

  return result
}

export async function getEmployeeByUuid(uuid) {
  if (!uuid) return

  const colRef = collection(getFirestore(), 'employee')
  const q = query(colRef, where('uuid', '==', uuid))
  const { docs } = await getDocs(q)

  return docs.length ? docs[0].data() : null
}
