import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { Modal, Row } from 'react-bootstrap'
import styles from 'styles/tree.module.css'
import { getAllEmployee, selectEmployeeFromUuids } from 'services/employee'
import FalconCloseButton from 'components/common/FalconCloseButton'
import { UserList } from '../common/UserCard'
import { ListContext } from '../employee/List'
import { useOrgMap } from 'hooks/firestore/org'
import { moveUserOrg } from 'services/org'

const userColProps = {
  xs: 12,
  sm: 6,
  md: 4
}

const OrgAddEmpModal = ({ active, setActive, org, refresh, childrenUuids }) => {
  const [orgEmployee, setOrgEmployee] = useState([], 'orgEmployee')
  const [employee, setEmployee] = useState([], 'employee')
  const orgNameMap = useOrgMap(orgEmployee)
  const orgNameMapAll = useOrgMap(employee)

  const getEmployee = async () => {
    await setAllEmployee()
    await setChildrenEmployee()
  }

  const runInit = async () => {
    await getEmployee()
  }

  useEffect(() => {
    if (active) {
      runInit()
    } else {
      setEmployee([])
      setOrgEmployee([])
    }
  }, [active])

  useEffect(() => {
    if (employee.length && orgEmployee.length) {
      removeDuplicate()
    }
  }, [employee.length, orgEmployee.length])

  const setChildrenEmployee = async () => {
    if (!childrenUuids.length) return

    const _employee = await selectEmployeeFromUuids(childrenUuids)
    setOrgEmployee(_employee)
  }

  const setAllEmployee = async () => {
    const _employee = await getAllEmployee()
    setEmployee(_employee)
  }

  const removeDuplicate = () => {
    orgEmployee.forEach((emp) => {
      setEmployee((state) => {
        return state.filter((innerEmp) => innerEmp.orgUuid !== emp.orgUuid)
      })
    })
  }

  const moveToRoot = async (u) => {
    const { uuid: userUuid } = u
    await moveUserOrg(userUuid, 'root', null, true)
    await runInit()
  }

  const moveToOrg = async (u) => {
    const { uuid: userUuid } = u
    await moveUserOrg(userUuid, org.uuid, org.parentUuid, false)
    await runInit()
  }

  return (
    <Modal show={active} onHide={() => setActive(false)} size="lg">
      <Modal.Header>
        <Modal.Title>인원 편성 - {org?.title}</Modal.Title>
        <FalconCloseButton onClick={() => setActive(false)} />
      </Modal.Header>
      <Modal.Body>
        <Row className={styles.empBody}>
          <div className={styles.empList}>
            <div>{org?.title}</div>
            <ListContext.Provider value={{ orgNameMap }}>
              <UserList
                users={orgEmployee}
                routable={false}
                userColProps={userColProps}
                selectable
                selectEvent={moveToRoot}
              />
            </ListContext.Provider>
          </div>
          <div className={styles.empList}>
            <div>
              모든 조직원 - <strong>인원 클릭시 위 조직으로 이관됩니다.</strong>
            </div>
            <ListContext.Provider value={{ orgNameMap: orgNameMapAll }}>
              <UserList
                users={employee}
                routable={false}
                userColProps={userColProps}
                selectable
                selectEvent={moveToOrg}
              />
            </ListContext.Provider>
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

OrgAddEmpModal.propTypes = {
  active: propTypes.bool,
  setActive: propTypes.func,
  org: propTypes.object,
  refresh: propTypes.func,
  childrenUuids: propTypes.array
}

export default OrgAddEmpModal
